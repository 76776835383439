import React from "react";
import "./Industries.css";

// components
import Usecase from "../../components/usecase/Usecase";
import Products from "../../components/products/Products";
import SubHeader from "../../components/subheader/SubHeader";

class Industries extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    renderData: null,
  };

  componentDidMount() {
    Promise.all([this.props.content]).then((res) => {
      this.setState({ renderData: res[0] });
    });
  }

  render() {
    return (
      <>
        {this.state.renderData && (
          <div className="industry">
            {/* subheader */}
            <SubHeader array={this.state.renderData.headerItems} />

            {/* landing */}
            <div
              className="landing"
              style={{
                backgroundImage: `url(${this.state.renderData.landings.bgimage})`,
              }}
            >
              <h1>{this.state.renderData.landings.heading}</h1>
              <div className="hr"></div>
              <h3>{this.state.renderData.landings.subhead}</h3>
            </div>

            {/* overview */}
            <div className="overview">
              <div className="overview-left">
                <div className="left-container">
                  <h1>{this.state.renderData.overview.catchline}</h1>
                  {/* add social media icons here */}
                  <ul>
                    <li id="first-social">
                      <a href="#">
                        <img src="https://r-e-a-l.it/Images-Updated/LinkedInPic.png"></img>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="https://r-e-a-l.it/Images-Updated/FB.png"></img>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="https://r-e-a-l.it/Images-Updated/Twitter.png"></img>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="https://r-e-a-l.it/Images-Updated/Instagram.png"></img>
                      </a>
                    </li>
                  </ul>
                  <button
                    className="button button--hyperion"
                    onClick={() => {
                      window.location.href = "#usecases";
                    }}
                  >
                    <span>
                      <span>{this.state.renderData.overview.buttontxt}</span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="overview-right">
                <div className="right-container">
                  <p>{this.state.renderData.overview.paragraph}</p>
                </div>
              </div>
            </div>

            {/* usecases */}
            <div className="usecases">
              <div id="usecases" class="related-products">
                <Usecase array={this.state.renderData.usecases} />
              </div>
            </div>

            {/* products */}
            <div className="products">
              <div id="products" class="related-products">
                <Products array={this.state.renderData.products} />
              </div>
            </div>

            <div style={{ width: "80vw" }}>
              <h1>{this.state.renderData.title}</h1>
              <p>{this.state.renderData.blurb}</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Industries;
