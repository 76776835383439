import React from "react";

class Usecase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 1,
      list: props.array,
    };
  }

  render() {
    const usecase = this.state.list.map((i) => {
      return (
        <div
          className="products-item"
          style={{ gridColumn: "span 1", listStyle: "none" }}
        >
          <img src={i.images}></img>
          <h1>{i.header}</h1>
          <hr className="pr-item-hr" />
          <p>{i.blurbs}</p>
          <button
            className="button button--hyperion"
            onClick={() => {
              window.open(i.link, "_blank");
            }}
            style={{ marginRight: "15px" }}
          >
            <span>
              <span>view case study</span>
            </span>
          </button>
        </div>
      );
    });
    return usecase;
  }
}

export default Usecase;
