import React from "react";
import "./Software.css";
import Contact from "../contact/Contact";
import SubHeader from "../../components/subheader/SubHeader";
import Products from "../../components/products/Products";

/* functions */

class Software extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    renderData: null,
  };

  componentDidMount() {
    Promise.all([this.props.content]).then((res) => {
      this.setState({ renderData: res[0] });
    });
  }

  /* domifier */
  domify(str) {
    let dom = document.createElement("p");
    dom.innerHTML = str;
    return dom;
  }

  /*

  subheader - items (technology, usecases, specifications)

  overview 

    landing - productMame, video:ink (vimeo only!)
    advertise - title, videoLink, paragraph, sectionGradient
    sketchfab - modelLink
    videoSection[] - title, videoLink, pararaph, (optional) sectionGradient

  features[] (technology, usecases)

    landing - isVideo, linkToMedia, title
    section[] - imgLink, paragraph

  specifications (statistics, datasets)

    statistics - statslist[], statsImg
    datasets[] - title, buttonText, downloadLink

  */

  render() {
    return (
      <>
        {this.state.renderData && (
          <div>
            <SubHeader array={this.state.renderData.headerItems} />
            <section id="landing">
              <h1 className="land-header-software ml3">
                {this.state.renderData.landing.productName}
              </h1>
              <hr className="land-divide" />
              <h2 className="land-subhead">
                {this.state.renderData.landing.subHeader}
              </h2>
              <img src={this.state.renderData.landing.imageLink}></img>
              <hr style={{ width: "75%", border: "1px solid #333" }} />
            </section>

            <section
              id={String(this.state.renderData.headerItems[0]).replaceAll(
                " ",
                ""
              )}
              className="advertise"
              style={{
                display: this.state.renderData.advertise.active
                  ? "initial"
                  : "none",
                // backgroundImage: `linear-gradient(#000000, ${this.state.renderData.sketchfab.gradientColor})`,
              }}
            >
              <div id="overview"></div>
              <h1 style={{ zIndex: "5", color: "white", padding: "25px" }}>
                {/* {this.state.renderData.advertise.title} (domify function isn't returning an error) */}
                {this.state.renderData.advertise.title}
                {/* The{" "}
     <span
       className="gradient-text"
       style={{
         backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
       }}
     >
       power
     </span>
     <br />
     of{" "}
     <span
       className="gradient-text"
       style={{
         backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
       }}
     >
       reality capture
     </span>{" "}
     technology
     <br />
     in the{" "}
     <span
       className="gradient-text"
       style={{
         backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
       }}
     >
       palm
     </span>{" "}
     of your hand. */}
              </h1>
              <iframe
                style={{ zIndex: "5" }}
                src={this.state.renderData.advertise.videoLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <p style={{ textAlign: "center", color: "#888", zIndex: "5" }}>
                {this.state.renderData.advertise.paragraph}
              </p>
            </section>

            <div
              id="features"
              style={{
                margin: "none",
                padding: "none",
                display: this.state.renderData.featuresActive
                  ? "initial"
                  : "none",
              }}
            >
              <section
                id={this.state.renderData.featuresSections[0].id}
                style={{
                  // backgroundImage: `linear-gradient(${this.state.renderData.sketchfab.gradientColor}, #000000)`,
                  display: this.state.renderData.featuresSections[0].active
                    ? "initial"
                    : "none",
                }}
              >
                <div
                  className="vidwrap"
                  // style={{
                  //   backgroundImage: `linear-gradient(${this.state.renderData.sketchfab.gradientColor}, #000000)`,
                  // }}
                >
                  <div className="vidleft">
                    <h1
                      className="gradient-text"
                      style={{
                        backgroundImage:
                          "linear-gradient(120deg, #24272A, #db0d0d)",
                      }}
                    >
                      {this.state.renderData.featuresSections[0].title}
                    </h1>
                    <hr
                      style={{
                        width: "100px",
                        float: "left",
                        border: "1px solid #333",
                      }}
                    />{" "}
                    <br />
                    <br />
                    <p>{this.state.renderData.featuresSections[0].paragraph}</p>
                  </div>
                  <div className="midline"></div>
                  <div className="vidright">
                    <img
                      className="yt-vid"
                      src={this.state.renderData.featuresSections[0].image}
                    ></img>
                  </div>
                </div>
              </section>

              <section
                id={this.state.renderData.featuresSections[1].id}
                style={{
                  display: this.state.renderData.featuresSections[1].active
                    ? "initial"
                    : "none",
                }}
              >
                <canvas className="hexagon-trail"></canvas>
                <div className="vidwrap" style={{ zIndex: "5" }}>
                  <div className="vidleft vid-order-hook">
                    <img
                      className="yt-vid"
                      src={this.state.renderData.featuresSections[1].image}
                    ></img>
                  </div>
                  <div className="midline"></div>
                  <div className="vidright text-order-hook">
                    <h1
                      className="gradient-text"
                      style={{
                        backgroundImage:
                          "linear-gradient(120deg, #24272A, #db0d0d)",
                      }}
                    >
                      {this.state.renderData.featuresSections[1].title}
                    </h1>
                    <hr
                      style={{
                        width: "100px",
                        float: "left",
                        border: "1px solid #333",
                      }}
                    />
                    <br />
                    <br />
                    <p>{this.state.renderData.featuresSections[1].paragraph}</p>
                  </div>
                </div>
              </section>

              <section
                id={this.state.renderData.featuresSections[2].id}
                style={{
                  display: this.state.renderData.featuresSections[2].active
                    ? "initial"
                    : "none",
                }}
              >
                <div className="vidwrap">
                  <div className="vidleft">
                    <h1
                      className="gradient-text"
                      style={{
                        backgroundImage:
                          "linear-gradient(120deg, #24272A, #db0d0d)",
                      }}
                    >
                      {this.state.renderData.featuresSections[2].title}
                    </h1>
                    <hr
                      style={{
                        width: "100px",
                        float: "left",
                        border: "1px solid #333",
                      }}
                    />
                    <br />
                    <br />
                    <p>{this.state.renderData.featuresSections[2].paragraph}</p>
                  </div>
                  <div className="midline"></div>
                  <div className="vidright">
                    <img
                      className="yt-vid"
                      src={this.state.renderData.featuresSections[2].image}
                    ></img>
                  </div>
                </div>
              </section>

              <section
                id={this.state.renderData.featuresSections[3].id}
                style={{
                  display: this.state.renderData.featuresSections[3].active
                    ? "initial"
                    : "none",
                }}
              >
                <div className="vidwrap" style={{ zIndex: "5" }}>
                  <div className="vidleft vid-order-hook">
                    <img
                      className="yt-vid"
                      src={this.state.renderData.featuresSections[3].image}
                    ></img>
                  </div>
                  <div className="midline"></div>
                  <div className="vidright text-order-hook">
                    <h1
                      className="gradient-text"
                      style={{
                        backgroundImage:
                          "linear-gradient(120deg, #24272A, #db0d0d)",
                      }}
                    >
                      {this.state.renderData.featuresSections[3].title}
                    </h1>
                    <hr
                      style={{
                        width: "100px",
                        float: "left",
                        border: "1px solid #333",
                      }}
                    />
                    <br />
                    <br />
                    <p>{this.state.renderData.featuresSections[3].paragraph}</p>
                  </div>
                </div>
              </section>
            </div>

            <div id="products" class="related-products">
              <Products array={this.state.renderData.compatibleProducts} />
            </div>

            <div
              id={String(this.state.renderData.headerItems[4]).replaceAll(
                " ",
                ""
              )}
            ></div>
            <Contact displayGradient={false} />

            <section id="ordering">
              <div className="order-options">
                <div className="ordering-column" id="order-border">
                  <h1>{this.state.renderData.ordering[0].title}</h1>
                  <p>{this.state.renderData.ordering[0].paragraph}</p>
                  <button
                    className="button button--hyperion"
                    onClick={() => {
                      window.location.href =
                        "mailto:experts@r-e-a-l.it?subject=More%20info%20about%20BLK2GO&body=Hello,%20I'm%20looking%20for%20more%20information%20about%20the%20BLK2GO.";
                    }}
                  >
                    <span>
                      <span>
                        {this.state.renderData.ordering[0].buttonText}
                      </span>
                    </span>
                  </button>
                </div>
                <div className="ordering-column">
                  <h1>{this.state.renderData.ordering[1].title}</h1>
                  <p>{this.state.renderData.ordering[1].paragraph}</p>
                  <button
                    className="button button--hyperion"
                    onClick={() => {
                      window.location.href =
                        "mailto:experts@r-e-a-l.it?subject=Quote%20for%20BLK2GO&body=Hello,%20please%20send%20me%20a%20quote%20for%20the%20BLK2GO.";
                    }}
                  >
                    <span>
                      <span>
                        {this.state.renderData.ordering[1].buttonText}
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="ordering-below">
                <img src="https://r-e-a-l.it/capture/images/etcetera/capture-reality.gif" />
              </div>
            </section>
          </div>
        )}
      </>
    );
  }
}

export default Software;
