import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { sheets } from "googleapis/build/src/apis/sheets";
// import { google } from "googleapis";

// const authentication = async () => {
//   const auth = new google.auth.GoogleAuth({
//     keyFile: "credentials.json",
//     scopes: "https://www.googleapis.com/auth/spreadsheets",
//   });

//   const client = await auth.getClient();

//   const sheets = google.sheets({
//     version: "v4",
//     auth: client,
//   });

//   return { sheets };
// };

// const id = "1GIGp1bb5yX7eOqQk9QadgFKuTLip8D7LVr7ppg-_d08";

// fetch(
//   "https://docs.google.com/spreadsheets/d/1GIGp1bb5yX7eOqQk9QadgFKuTLip8D7LVr7ppg-_d08/edit#gid=1278399958"
// ).then((response) => response.json());

// app.get("/", async (req, res) => {
//   try {
//     const { sheets } = await authentication();

//     // reading and storing and sheets
//     const reponse = await sheets.spreadsheets.values.get({
//       spreadsheetId: id,
//       range: "Sheet1",
//     });
//     res.send(response.data);
//   } catch (e) {
//     console.log(e);
//     res.status(500).send();
//   }
//   console.log(sheets);
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
